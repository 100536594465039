<template>
  <div class="signatureFour">
    <div class="flex">
      <div id="signatureFour" ></div>
      <div class="footer flex">
        <van-button type="info" @click="save" color="#3F7C53" id="sign_ok"><span>保</span><span>存</span><span>签</span><span>名</span> </van-button>
        <van-button @click="clear" class="clear-btn" id="sign_clear"><span>重</span><span>写</span></van-button>
      </div>
    </div>
    <!-- <div class="desc">
       <span>注</span>
       <span>：</span>
       <span>请</span>
       <span>在</span>
       <span>框</span>
       <span>内</span>
       <span>书</span>
       <span>写</span>
       <span>签</span>
       <span>名</span>
    </div> -->

  </div>
</template>

<script>
import { Button, Toast } from 'vant'
import { saveFileInfo , getHomeInfo} from '@/utils/api'
import common from '@/utils/common'
import {uwStatisticAction} from "../../utils/util";
export default {
  components:{
    [Button.name]: Button,
    [Toast.name]: Toast
  },
  data() {
    return {
      imgsrc: '',
      signStr:''
    }
  },
  created() {
    getHomeInfo({uniqueNo:common.getItem('uniqueNo')}).then(res=>{
      if(res.code===200&&res.data.businessCode===200){
        this.signStr = res.data
      }
    })
  },
  methods: {
    clear() {
      $("#signatureFour").jSignature("reset")
    },
    callback(base64data){
      saveFileInfo({
        fileType: 'perSign',
        subType: 'Sign',
        stationNo: this.$route.query.stationNo,
        token: common.getItem('wtToken'),
        picOwnNo: common.getItem('masterNo') ? common.getItem('masterNo') : common.getItem('uniqueNo') , // 是站长是传
        fileContent: base64data,
      }).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            common.setItem('signAgent', true);
            if(this.signStr.stationStep==='END' && this.signStr.stepStr==='您有协议待签署'){
              this.$router.push({name:'notSign'});
            }else{
              this.$router.push({name:'waitSign', query:{stationNo: this.$route.query.stationNo}})
            }

          }
        }
      })
    },
    save() {
      var datapair = $("#signatureFour").jSignature("getData", "image")
      var array = datapair.splice(",")
      // data:image/png;base64,
      // console.log("data:" + array[0] + "," + array[1])

      let imgSrc= "data:" + array[0] + "," + array[1]
      let _this = this
      common.rotateBase64Img(imgSrc, 90, (base64)=>{
        _this.callback(base64)
      })
    },
  },
  mounted() {
    $(document).ready(function() {
      let params = {
        // width: '6.5rem',
        // height: document.body.clientHeight + 'px',
        // lineWidth: '3'

        width: (document.body.clientWidth - 98 ) + 'px' ,
        height: document.body.clientHeight + 'px',
        lineWidth: '3'
      };
      $("#signatureFour").jSignature(params)
    });
    uwStatisticAction('/site/signatureFour','站长签约协议')
  }
}
</script>

<style lang="less" scoped>
.signatureFour{
  height: 100%;
  background: #f5f5f5;
  flex-direction: column;
  .flex{
    display: flex;
  }
  .desc{
    flex:1;
    height: 100%;
    text-align: center;
    span{
      display: inline-block;
      transform: rotate(-90deg);
    }
  }
  .content{
    height: 80%;
  }
  .sign_show{
    width: 2rem;
    height: 1rem;
    border: 0.01rem solid #666;
  }
  .footer{
    flex-direction: column;
    .clear-btn{
      background: #999;
      color: #fff;
    }
    .van-button{
      width: 100%;
      flex:1;
      .van-button__text{
        transform: rotate(-90deg) translateY(-0.35rem);
        display: inline-block;
      }
    }
  }
}
</style>
